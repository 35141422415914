import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import {
  Formik,
  useFormikContext,
  Form,
  Field,
  FieldProps,
  FieldArray,
  ArrayHelpers,
  FormikHelpers,
  yupToFormErrors,
} from 'formik';
import axios from 'axios';
import { v4 } from 'uuid';
import { useParams } from 'react-router-dom';
import SectionTitle from '../../../components/sectionTitle/SectionTitle';
import Flex from '../../../components/layout/Flex';
import Avatar from '../../../components/avatar/Avatar';
import theme from '../../../theme/theme';
import Button from '../../../components/button/Button';
import Separator from '../../../components/separator/Separator';
import Accordion from '../../../components/accordion/Accordion';
import Grid, { GridItem } from '../../../components/layout/Grid';
import Goal from '../../prospects/components/Goal';
import Input from '../../../components/input/Input';
import NativeSelect from '../../../components/select/NativeSelect';
import Stack from '../../../components/stack/Stack';
import MiniNav from '../../prospects/components/MiniNav';
import BoxSelect from '../../../components/boxselect/BoxSelect';
import FileUploader from '../../../components/uploader/FileUploader';
import StatCard from '../../../components/stat/StatCard';
import Confirm from '../../../components/confirm/Confirm';
import {
  useProspectDetailQuery,
  useGendersQuery,
  useIndustriesQuery,
  useRelationshipsQuery,
  usePaymentChannelQuery,
  usePaymentProviderQuery,
  useModeofInstructionCommunicationQuery,
  useIdentificationTypeQuery,
  useUpdate_User_DetailMutation,
  useGenerate_Signeds3_Request_For_UploadMutation,
  useIncomeSourceQuery,
  useContributionSourceQuery,
  useCountryQuery,
  Member_Goal,
  Goal_Insurance_Constraint,
  Goal_Insurance_Update_Column,
  useGenerate_Signeds3_Request_For_DownloadMutation,
  useBranchQuery,
  useGoalsQuery,
  useMaritalStatusQuery,
  useRegionsQuery,
  useTitlesQuery
} from '../../../generated/graphql';
import { ProspectEntry, FormData } from '../../../utils/types';
import { EditCustomerJointTrustSchema } from '../../../utils/yup-schema';
import ErrorMessage from '../../../components/ErrorMessage';
import {
  formatFilenameForS3IDUpload,
  getDate18YearsAgo,
} from '../../../utils/helpers';
import GoalCalculatorModal from '../../prospects/components/GoalCalculatorModal';
import { GoalModal } from '../../../containers/GoalModal';
import DateInput from '../../../components/date/DateInput';
import { format } from 'date-fns';
import Icon from '../../../components/icon/Icon';
import { useAuthState } from '../../../context/auth';
import Select, { ActionMeta, ValueType } from 'react-select';
import moment from 'moment';
import {
  accountActivity,
  accountMandates,
  AccountType,
  customer_type,
  approximateAnnualIncome,
  employementStatus,
  frequencies,
  investmentHorizons,
  investmentKnowledge,
  investmentObjectives,
  modeOfDeliveries,
  riskTolerance,
  sections
} from '../../../shared/sections';
import { toast } from 'react-toastify';
import Toastify from '../../../components/toastify/Toastify';
import { useHistory } from 'react-router-dom';
/**@jsx jsx*/

interface IProp {
  prospect?: ProspectEntry;
  id?: number;
  loading: boolean;
  data: boolean;
  error: boolean;
  errorMessage: string;
}

type Target = {
  contribution_source?: {
    id: string;
    name: string;
  };
  income_source?: {
    id: string;
    name: string;
  };
};

const NewBeneficiary = {
  first_name: '',
  last_name: '',
  other_names: '',
  date_of_birth: '',
  mobile: '',
  relationship_id: '',
  allocation: '',
};

const NewDependent = {
  name: '',
  age: '',
};

const NewChannel = {
  guid: v4(),
  account_name: '',
  account_number: '',
  branch_id: '',
  payment_provider: {
    id: '',
    payment_channel_id: '',
  },
  preferred: false,
  mobile_money_number: '',
};

const MEMBER_INCOME_SOURCES = 'member_income_sources';
const MEMBER_CONTRIBUTION_SOURCES = 'member_contribution_sources';

const EditJointCustomer: React.FC<IProp> = ({
  prospect,
  loading,
  data,
  error,
  errorMessage,
}) => {
  //fetch first
  const { data: incomeSources } = useIncomeSourceQuery();
  const { data: contributionSources } = useContributionSourceQuery();

  const [goalId, setGoalId] = React.useState('');
  const [idImagePreviewURL, setIDImagePreviewURL] = React.useState('');
  const [saveDialogIsOpen, setSaveDialogIsOpen] = React.useState(false);
  const [goalCalculatorModalIsOpen, setGoalCalculatorIsOpen] = React.useState(
    false
  );
  const [goalArrayHelper, setGoalArrayHelper] = React.useState<ArrayHelpers>();
  const [
    goalContainerModalIsOpen,
    setGoalContainerModalIsOpen,
  ] = React.useState(false);

  const {
    values,
    handleChange,
    handleBlur,
    setFieldValue,
    submitForm,
    isSubmitting,
    validateForm,
    isValid,
  } = useFormikContext<FormData>();
  const { data: genders } = useGendersQuery();
  const { data: industries } = useIndustriesQuery();
  const { data: relationships } = useRelationshipsQuery();
  const { data: paymentChannels } = usePaymentChannelQuery();
  const { data: paymentProviders } = usePaymentProviderQuery();
  const { data: identificationTypes } = useIdentificationTypeQuery();
  const { data: modeOfInstructions } = useModeofInstructionCommunicationQuery();
  const { data: branches } = useBranchQuery();
  const { data: countries } = useCountryQuery();
  const { data: goals } = useGoalsQuery();
  const { data: titles } = useTitlesQuery();
  const { data: maritalStatuses } = useMaritalStatusQuery();
  const { data: regions } = useRegionsQuery();

  const [
    generateSignedS3RequestForDownload,
  ] = useGenerate_Signeds3_Request_For_DownloadMutation();

  // user gender data
  const userGender = values?.user?.gender;

  //user industry or sector
  const userIndustry = values?.industry;

  // user region data
  const userRegion = values?.region;

  const idCardImageFilename =
    values.member_identifications?.[0]?.identification_card_image_url;

  React.useEffect(() => {
    const getIDCardImageURL = async (idCardImageFilename: string) => {
      const ext = idCardImageFilename.split('.')[0];
      if (ext) {
        const response = await generateSignedS3RequestForDownload({
          variables: {
            filetype: ext,
            filename: idCardImageFilename,
          },
        });
        const signedURL =
          response?.data?.generateSignedS3RequestForDownload?.signedRequest;
        if (signedURL) {
          setIDImagePreviewURL(signedURL);
        }
      }
    };
    if (idCardImageFilename) {
      getIDCardImageURL(idCardImageFilename);
    }
  }, [idCardImageFilename, generateSignedS3RequestForDownload]);

  React.useEffect(() => {
    setSaveDialogIsOpen(false);
  }, [data]);

  const getComboDefaultSelected = (name: string) => {

    if (name === MEMBER_INCOME_SOURCES) {
      return values?.member_income_sources?.filter(src => src.income_source_id !== 5).map(
        (source) =>
        ({
          income_source: {
            id: source?.income_source?.id,
            name: source?.income_source?.name,
          },
        } || [])
      );
    }

    if (name === MEMBER_CONTRIBUTION_SOURCES) {
      return values?.member_contribution_sources?.filter(src => src.contribution_source_id !== 6).map(
        (source) =>
        ({
          contribution_source: {
            id: source?.contribution_source?.id,
            name: source?.contribution_source?.name,
          },
        } || [])
      );
    }
  };

  const getComboOptions = (name: string) => {
    if (name === MEMBER_INCOME_SOURCES) {
      return (
        incomeSources?.income_source?.filter(src => src.id !== 5).map((source) => ({
          income_source: {
            id: source.id,
            name: source.name,
          },
        })) || []
      );
    }

    if (name === MEMBER_CONTRIBUTION_SOURCES) {
      return (
        contributionSources?.contribution_source?.filter(src => src.id !== 6).map((source) => ({
          contribution_source: {
            id: source.id,
            name: source.name,
          },
        })) || []
      );
    }
  };

  async function confirmSaveProspect() {
    const response = await validateForm();
    // @ts-ignore
    // prettier-ignore
    console.log("ValidateFormResponse", response);
    if (isValid) {
      setSaveDialogIsOpen(true);
    }
  }

  const acceptedGoals = () => {
    return values?.member_goals?.filter((goal) => goal.accepted === true) || [];
  };

  const getPaymentProviders = (id: number | string) => {
    return (
      paymentProviders?.payment_provider?.filter(
        (provider) => provider.payment_channel?.id === Number(id) && ![30, 23].includes(provider.id)
      ) || []
    );
  };

  const getBranches = (id: number | string) => {
    return (
      branches?.branch?.filter(
        (branch) => branch.payment_provider_id === Number(id)
      ) || []
    );
  };

  // const filteredTitles = () => {
  //   return titles?.title?.filter((title) => crmTitles.includes(title.name));
  // }

  const isModeChecked = (mode: number) => {
    const modePresent = values?.member_mode_of_instruction_communications?.find(
      (member_mode) => member_mode.mode_of_instruction_communication_id === mode
    );

    return !!modePresent;
  };

  const pickModeIcon = (name: string) => {
    switch (name) {
      case 'Email with indemnity':
        return 'at';
      case 'Physical / Walk-In':
        return 'person-walking';
      case 'Mobile':
        return 'mobile-retro';
      default:
        return '';
    }
  };

  const handleDateChange = (e: any) => {
    const { name, value } = e.target;
    if (value) {
      const formattedDate = format(value, 'yyyy-MM-dd');
      setFieldValue(name, formattedDate);
    }
  };

  const handleComboChange = (
    value: ValueType<Target>,
    actionMeta: ActionMeta<Target>
  ) => {
    const { name } = actionMeta;
    setFieldValue(name as string, value);
  };

  const handleSignatureChange = (e: any) => {
    e.preventDefault();
    const imageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    let file: File = e.target.files[0];
    if (file && imageTypes.includes(file.type)) {
      let reader = new FileReader();
      reader.onloadend = () => {
        setFieldValue('signature', reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleIdImageChange = (e: any) => {
    e.preventDefault();
    const imageTypes = ['image/gif', 'image/jpeg', 'image/png'];
    let file: File = e.target.files[0];
    if (file) {
      setFieldValue('id_file', file);
      if (imageTypes.includes(file.type)) {
        let reader = new FileReader();
        reader.onloadend = () => {
          setIDImagePreviewURL(reader.result as string);
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const handleKeyPress = (e: any) => {
    return !/[0-9]/.test(e.key) && e.preventDefault()
  }
  // console.log(getComboDefaultSelected(
  //   MEMBER_INCOME_SOURCES
  // ) as ValueType<Target>)

  return (
    <React.Fragment>
      {goalContainerModalIsOpen && (
        <GoalModal
          guid={goalId}
          arrayHelpers={goalArrayHelper}
          goals={goals!.goal.map((goal) => {
            return {
              id: goal.id,
              name: goal.name,
            };
          })}
          onClose={() => setGoalContainerModalIsOpen(false)}
        />
      )}

      {goalCalculatorModalIsOpen && (
        <GoalCalculatorModal
          guid={goalId}
          onClose={() => setGoalCalculatorIsOpen(false)}
        />
      )}

      {saveDialogIsOpen && (
        <Confirm
          title="Update Customer"
          message={
            data
              ? 'Customer details updated successfully'
              : error
                ? errorMessage?.includes('user_email_key')
                  ? 'User with email already eists'
                  : 'Failed to update customer details'
                : 'Are you sure you want to update the details?'
          }
          confirm={{
            label: 'Yes, Update customer',
            function: submitForm,
            loading: loading || isSubmitting,
            className: 'actual-update-btn',
          }}
          cancel={{
            label: 'No, Cancel',
            function: () => {
              setSaveDialogIsOpen(false);
            },
          }}
          icon={{
            name: error ? 'check' : 'check',
            color: error ? 'red' : 'green',
          }}
          onClose={() => setSaveDialogIsOpen(false)}
        />
      )}
      <SectionTitle
        gap={48}
        useBreadCrumb
      // routes={[
      //   { name: "Customers", route: `/dashboard/customers`, id: 6 },
      //   { name: "Customer", route: `/dashboard/customers/${id}`, id: 7 },
      //   {
      //     name: "Edit",
      //     route: "/dashboard/customers/edit/:id",
      //     id: 8,
      //   },
      // ]}
      ></SectionTitle>
      <Grid css={{ marginBottom: '64px' }}>
        <GridItem span={2}>
          <div css={{ position: 'fixed' }}>
            <MiniNav sections={sections} />
          </div>
        </GridItem>
        <GridItem span={10}>
          <Flex ai="center" jc="space-between">
            <Flex ai="center">
              <Avatar
                name={`${prospect?.user?.first_name ?? ''} ${prospect?.user?.last_name ?? ''
                  }`}
                background={theme.colors.blue[50]}
                css={{
                  marginRight: '16px',
                  color: theme.colors.primary,
                }}
                size="md"></Avatar>
              <div>
                <h2 css={{ fontSize: '14px' }}>
                  {`${prospect?.user?.first_name ?? ''} ${prospect?.user?.last_name ?? ''
                    }`}
                </h2>
                <p css={{ color: theme.colors.gray[400], fontSize: '12px' }}>
                  {`${prospect?.user?.email ?? ''}`}
                </p>
              </div>
            </Flex>

            <Stack spacing={8} isInline>
              <Button action={confirmSaveProspect} className="approve-btn">
                Save
              </Button>
            </Stack>
          </Flex>
          <Separator gap={36} />
          <Form>
            <Stack spacing={24}>
              <Accordion
                id="acceptedgoals"
                defaultState="expanded"
                title="Accepted Goals">
                <div>
                  <FieldArray
                    name="member_goals"
                    render={(arrayHelpers: ArrayHelpers) => (
                      <Flex>
                        <Button
                          type="button"
                          action={() => {
                            setGoalArrayHelper(arrayHelpers);
                            setGoalContainerModalIsOpen(true);
                          }}>
                          Add {values.member_goals?.length ? 'another' : ''}{' '}
                          goal
                        </Button>
                      </Flex>
                    )}
                  />
                  <br></br>
                </div>
                <Grid lg={3}>
                  {acceptedGoals().map((goal, index) => (
                    <Goal
                      key={goal.id}
                      data={goal}
                      onEdit={() => {
                        setGoalId(goal.guid);
                        setGoalCalculatorIsOpen(true);
                      }}
                    />
                  ))}
                </Grid>
              </Accordion>

              <Accordion id="bio" defaultState="expanded" title="Bio">
                <SectionTitle title="Applicant Details"></SectionTitle>
                <Grid lg={3}>
                  <div>
                    <Input
                      placeholder="Account Type"
                      label="Account Type"
                      value={values.account_type === customer_type.joint ? AccountType.joint : ''}
                      defaultValue="account_type"
                      name="account_type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled
                    />
                    <ErrorMessage name="account_type" />
                  </div>
                  <div>
                    <Input
                      placeholder="First name"
                      label="First name"
                      name="user.first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user?.first_name ?? ''}
                    />
                    <ErrorMessage name="user.first_name" />
                  </div>
                  <div>
                    <Input
                      placeholder="Last name"
                      label="Last name"
                      name="user.last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user?.last_name ?? ''}
                    />
                    <ErrorMessage name="user.last_name" />
                  </div>
                  <div>
                    <Input
                      placeholder="Other names"
                      label="Other names"
                      name="user.other_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user?.other_name ?? ''}
                    />
                    <ErrorMessage name="user.other_name" />
                  </div>
                  <div>
                    <DateInput
                      label="Date of birth"
                      name="user.date_of_birth"
                      defaultDate={
                        values.user?.date_of_birth &&
                        new Date(values.user?.date_of_birth)
                      }
                      onChange={handleDateChange}
                      maxDate={getDate18YearsAgo()}
                    />
                    <ErrorMessage name="user.date_of_birth" />
                  </div>
                  <div>
                    <Input
                      placeholder="Place of birth"
                      label="Place of birth"
                      name="place_of_birth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.place_of_birth ?? ''}
                    />
                    <ErrorMessage name="place_of_birth" />
                  </div>
                  <div>
                    <Field name="marital_status_id">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Marital Status" {...field}>
                          <option>Select martial status</option>
                          {maritalStatuses?.marital_status?.map((ms) => (
                            <option
                              key={ms.id}
                              value={ms.id}
                              selected={
                                values?.marital_status_id === ms.id
                                  ? true
                                  : false
                              }>
                              {ms.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="marital_status_id" />
                  </div>
                  <div>
                    <Field name="user.gender_id">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Gender" {...field}>
                          {genders?.gender?.filter(gen => gen.id !== 4).map((gender) => (
                            <option
                              key={gender.id}
                              value={gender.id}
                              selected={
                                userGender?.id === gender.id ? true : false
                              }>
                              {gender.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="user.gender_id" />
                  </div>
                  <div>
                    <Input
                      placeholder="Phone"
                      label="Phone (Primary)"
                      name="user.mobile"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user?.mobile ?? ''}
                    />
                    <ErrorMessage name="user.mobile" />
                  </div>
                  <div>
                    <Input
                      placeholder="Email"
                      label="Email"
                      name="user.email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.user?.email ?? ''}
                    />
                    <ErrorMessage name="user.email" />
                  </div>
                  <div>
                    <Input
                      placeholder="Postal address"
                      label="Postal address"
                      name="postal_address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.postal_address ?? ''}
                    />
                    <ErrorMessage name="postal_address" />
                  </div>
                  <div>
                    <Input
                      placeholder="Nationality"
                      label="Nationality"
                      name="nationality"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nationality ?? ''}
                    />
                    <ErrorMessage name="nationality" />
                  </div>
                  <div>
                    <Input
                      placeholder="TIN"
                      label="TIN"
                      name="tin"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tin ?? ''}
                    />
                    <ErrorMessage name="tin" />
                  </div>
                  <div>
                    <Input
                      placeholder="City"
                      label="City"
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city ?? ''}
                    />
                    <ErrorMessage name="city" />
                  </div>
                  <div>
                    <Input
                      placeholder="Residential Address"
                      label="Residential Address"
                      name="home_address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.home_address ?? ''}
                    />
                    <ErrorMessage name="home_address" />
                  </div>
                  <div>
                    <Input
                      placeholder="GPS Address"
                      label="GPS Address"
                      name={`gps_address`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.gps_address ?? ''}
                    />
                    <ErrorMessage name={`gps_address`} />
                  </div>
                </Grid>
                {/* joint account  */}
                <Separator gap={26} />
                <SectionTitle title="Applicant 2 Details"></SectionTitle>
                <Grid lg={3}>
                  <div>
                    <Input
                      placeholder="First name"
                      label="First name (JT)"
                      name="user.first_name_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user?.first_name_jt ?? ''}
                    />
                    <ErrorMessage name="user.first_name_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Last name"
                      label="Last name (JT)"
                      name="user.last_name_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user?.last_name_jt ?? ''}
                    />
                    <ErrorMessage name="user.last_name_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Other names"
                      label="Other names (JT)"
                      name="user.other_name_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user?.other_name_jt ?? ''}
                    />
                    <ErrorMessage name="user.other_name_jt" />
                  </div>
                  <div>
                    <DateInput
                      label="Date of birth (JT)"
                      name="user.date_of_birth_jt"
                      defaultDate={
                        values.user?.date_of_birth_jt
                          ? values.user?.date_of_birth_jt &&
                          new Date(values.user?.date_of_birth_jt)
                          : moment(getDate18YearsAgo(), 'YYYY-MM-DD').toDate()
                      }
                      onChange={handleDateChange}
                      maxDate={moment(
                        getDate18YearsAgo(),
                        'YYYY-MM-DD'
                      ).toDate()}
                    />
                    <ErrorMessage name="user.date_of_birth_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Place of birth"
                      label="Place of birth (JT)"
                      name="place_of_birth_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.place_of_birth_jt ?? ''}
                    />
                    <ErrorMessage name="place_of_birth_jt" />
                  </div>
                  <div>
                    <Field name="marital_status_id_jt">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Marital Status (JT)" {...field}>
                          <option>Select martial status</option>
                          {maritalStatuses?.marital_status?.map((ms) => (
                            <option
                              key={ms.id}
                              value={ms.id}
                              selected={
                                values?.marital_status_id_jt === ms.id
                                  ? true
                                  : false
                              }>
                              {ms.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="marital_status_id_jt" />
                  </div>
                  <div>
                    <Field name="user.gender_id_jt">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Gender (JT)" {...field}>
                          {genders?.gender?.filter(gen => gen.id !== 4).map((gender) => (
                            <option
                              key={gender.id}
                              value={gender.id}
                              selected={
                                values?.user?.gender_id_jt === gender.id ? true : false
                              }>
                              {gender.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="user.gender_id_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Phone"
                      label="Phone (JT) "
                      name="user.mobile_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.user?.mobile_jt ?? ''}
                    />
                    <ErrorMessage name="user.mobile_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Email"
                      label="Email (JT)"
                      name="user.email_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.user?.email_jt ?? ''}
                    />
                    <ErrorMessage name="user.email_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="TIN"
                      label="TIN (JT)"
                      name="tin_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.tin_jt ?? ''}
                    />
                    <ErrorMessage name="tin_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Residential Address"
                      label="Residential Address (JT)"
                      name="home_address_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.home_address_jt ?? ''}
                    />
                    <ErrorMessage name="home_address_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="GPS Address"
                      label="GPS Address (JT)"
                      name="gps_address_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.gps_address_jt ?? ''}
                    />
                    <ErrorMessage name={`gps_address_jt`} />
                  </div>
                </Grid>
              </Accordion>

              {/* residential info */}
              <Accordion id="residentialinfo" defaultState="expanded" title="Residential Info">
                <SectionTitle title="Applicant Residential Info"></SectionTitle>
                <Grid lg={3}>
                  <div>
                    <Field name="residential_status">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Residential Status" {...field}>
                          <option>Select Residential Status</option>
                          {[
                            'Resident_Ghanaian',
                            'Resident_Foreigner',
                            'Non_Resident_Ghanaian',
                            'Non_Resident_Foreigner',
                          ].map((stat) => (
                            <option
                              key={stat}
                              value={stat}
                              selected={
                                stat === values?.residential_status
                                  ? true
                                  : false
                              }>
                              {stat}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="residential_status" />
                  </div>
                  <div>
                    <Field name="country_of_origin_id">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Country Of Origin" {...field}>
                          <option value="">Select Country Of Origin</option>
                          {countries?.country.map((country) => (
                            <option
                              key={country.id}
                              value={country.id}
                              selected={
                                country.id === values?.country_of_origin_id
                                  ? true
                                  : false
                              }>
                              {country.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="country_of_origin_id" />
                  </div>
                  <div>
                    <Field name="country_of_residence_id">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Country Of Residence" {...field}>
                          <option value="">Select Country Of Residence</option>
                          {countries?.country.map((country) => (
                            <option
                              key={country.id}
                              value={country.id}
                              selected={
                                country.id === values?.country_of_residence_id
                                  ? true
                                  : false
                              }>
                              {country.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="country_of_residence_id" />
                  </div>
                  <div>
                    <div>
                      <Field name="region_id">
                        {({ field }: FieldProps) => (
                          <NativeSelect label="Region" {...field}>
                            <option value="">Select Region</option>
                            {regions?.region?.map((region) => (
                              <option
                                key={region.id}
                                value={region.id}
                                selected={
                                  userRegion?.id === region.id ? true : false
                                }>
                                {region.name}
                              </option>
                            ))}
                          </NativeSelect>
                        )}
                      </Field>
                      <ErrorMessage name="region_id" />
                    </div>
                  </div>
                  <div>
                    <Input
                      placeholder="Residential Permit Number"
                      label="Residential Permit Number"
                      name="residential_permit_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.residential_permit_number ?? ''}
                    />
                    <ErrorMessage name="residential_permit_number" />
                  </div>
                  <div>
                    <DateInput
                      label="Permit Issue Date"
                      name="permit_issue_date"
                      defaultDate={
                        values?.permit_issue_date &&
                        new Date(values.permit_issue_date)
                      }
                      onChange={handleDateChange}
                      maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                    />
                    <ErrorMessage name={`permit_issue_date`} />
                  </div>
                  <div>
                    <DateInput
                      label="Permit Expiry Date"
                      name="permit_expiry_date"
                      defaultDate={
                        values?.permit_expiry_date &&
                        new Date(values.permit_expiry_date)
                      }
                      onChange={handleDateChange}
                    />
                    <ErrorMessage name={`permit_expiry_date`} />
                  </div>
                  <div>
                    <Input
                      placeholder="Place of Issue"
                      label="Place of Issue"
                      name="residential_place_of_issue"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={values.residential_place_of_issue ?? ''}
                    />
                    <ErrorMessage name="residential_place_of_issue" />
                  </div>
                </Grid>

                {/* joint account */}
                <Separator gap={26} />
                <SectionTitle title="Applicant 2 Residential Info" />
                <Grid lg={3}>
                  <div>
                    <Field name="residential_status_jt">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Residential Status (JT)" {...field}>
                          <option>Select Residential Status</option>
                          {[
                            'Resident_Ghanaian',
                            'Resident_Foreigner',
                            'Non_Resident_Ghanaian',
                            'Non_Resident_Foreigner',
                          ].map((stat) => (
                            <option
                              key={stat}
                              value={stat}
                              selected={
                                stat === values?.residential_status_jt
                                  ? true
                                  : false
                              }>
                              {stat}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="residential_status_jt" />
                  </div>
                  <div>
                    <Field name="country_of_origin_id_jt">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Country Of Origin (JT)" {...field}>
                          <option value="">Select Country Of Origin</option>
                          {countries?.country.map((country) => (
                            <option
                              key={country.id}
                              value={country.id}
                              selected={
                                country.id === values?.country_of_origin_id_jt
                                  ? true
                                  : false
                              }>
                              {country.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="country_of_origin_id_jt" />
                  </div>
                  <div>
                    <Field name="country_of_residence_id_jt">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Country Of Origin (JT)" {...field}>
                          <option value="">Select Country Of Origin</option>
                          {countries?.country.map((country) => (
                            <option
                              key={country.id}
                              value={country.id}
                              selected={
                                country.id === values?.country_of_residence_id_jt
                                  ? true
                                  : false
                              }>
                              {country.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="country_of_residence_id_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Residential Permit Number"
                      label="Residential Permit Number (JT)"
                      name="residential_permit_number_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.residential_permit_number_jt ?? ''}
                    />
                    <ErrorMessage name="residential_permit_number_jt" />
                  </div>
                  <div>
                    <DateInput
                      label="Permit Issue Date (JT)"
                      name="permit_issue_date_jt"
                      defaultDate={
                        values?.permit_issue_date_jt &&
                        new Date(values.permit_issue_date_jt)
                      }
                      onChange={handleDateChange}
                      maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                    />
                    <ErrorMessage name={`permit_issue_date_jt`} />
                  </div>
                  <div>
                    <DateInput
                      label="Permit Expiry Date (JT)"
                      name="permit_expiry_date_jt"
                      defaultDate={
                        values?.permit_expiry_date_jt &&
                        new Date(values.permit_expiry_date_jt)
                      }
                      onChange={handleDateChange}
                    />
                    <ErrorMessage name={`permit_expiry_date_jt`} />
                  </div>
                  <div>
                    <Input
                      placeholder="Place of Issue"
                      label="Place of Issue (JT)"
                      name="residential_place_of_issue_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      defaultValue={values.residential_place_of_issue_jt ?? ''}
                    />
                    <ErrorMessage name="residential_place_of_issue_jt" />
                  </div>
                </Grid>
                <Separator gap={24} />
                <SectionTitle title="Identification "></SectionTitle>
                <Grid lg={3}>
                  <div>
                    <NativeSelect
                      label="ID Type (JT)"
                      name="id_type_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.id_type_jt}>
                      <option value="">Select ID type</option>
                      {identificationTypes?.identification_type.map(
                        (type) => (
                          <option key={type.id} value={type.name}>
                            {type.name}
                          </option>
                        )
                      )}
                    </NativeSelect>
                    <ErrorMessage name="id_type_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="ID number"
                      label="ID number (JT)"
                      name="id_number_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.id_number_jt ?? ''}
                    />
                    <ErrorMessage name="id_number_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Place of Issue"
                      label="Place of Issue (JT)"
                      name="place_of_issue_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.place_of_issue_jt ?? ''}
                    />
                    <ErrorMessage name="place_of_issue_jt" />
                  </div>
                  <div>
                    <DateInput
                      label="Issue date (JT)"
                      name="id_issue_date_jt"
                      defaultDate={
                        values.id_issue_date_jt &&
                        new Date(
                          values.id_issue_date_jt
                        )
                      }
                      onChange={handleDateChange}
                      maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                    />
                    <ErrorMessage name="id_issue_date_jt" />
                  </div>
                  <div>
                    <DateInput
                      label="Expiry date (JT)"
                      name="id_expiry_date_jt"
                      defaultDate={
                        values.id_expiry_date_jt
                          ? values.id_expiry_date_jt
                            ? values.id_expiry_date_jt &&
                            new Date(values.id_expiry_date_jt)
                            : values.id_expiry_date_jt
                          : moment().add(1, 'days').toDate()
                      }
                      onChange={handleDateChange}
                      minDate={moment().add(1, 'days').toDate()}
                    />
                    <ErrorMessage name="id_expiry_date_jt" />
                  </div>
                </Grid>
              </Accordion>

              {/* occupation */}
              <Accordion id="occupation" title="Occupation">
                <SectionTitle title="Applicant Occupation"></SectionTitle>
                <Grid lg={3}>
                  <div>
                    <Field name="employment_status">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Employment Status" {...field}>
                          <option value="">Select Employment Status</option>
                          {employementStatus.map((emp) => (
                            <option
                              key={emp}
                              value={emp}
                              selected={
                                emp === values?.employment_status ? true : false
                              }>
                              {emp}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="employment_status" />
                  </div>
                  <div>
                    <Field name="industry_id">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Sector" {...field}>
                          {industries?.industry?.map((industry) => (
                            <option
                              key={industry.id}
                              value={industry.id}
                              selected={
                                industry.id === userIndustry?.id ? true : false
                              }>
                              {industry.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="industry_id" />
                  </div>
                  <div>
                    <Input
                      placeholder="Banker"
                      label="Occupation"
                      name="occupation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.occupation ?? ''}
                    />
                    <ErrorMessage name="occupation" />
                  </div>
                  <div>
                    <Input
                      placeholder="Profession"
                      label="Profession"
                      name="profession"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.profession ?? ''}
                    />
                    <ErrorMessage name="profession" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer"
                      label="Employer/Business/School"
                      name="employer"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer ?? ''}
                    />
                    <ErrorMessage name="employer" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer Address"
                      label="Employer Address"
                      name="employer_address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer_address ?? ''}
                    />
                    <ErrorMessage name="employer_address" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer City/Town"
                      label="Employer City"
                      name="employer_city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer_city ?? ''}
                    />
                    <ErrorMessage name="employer_city" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer Email"
                      label="Employer Email"
                      name="employer_email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer_email ?? ''}
                    />
                    <ErrorMessage name="employer_email" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer Contact"
                      label="Employer Contact"
                      name="employer_contact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer_contact ?? ''}
                    />
                    <ErrorMessage name="employer_contact" />
                  </div>
                  <div>
                    <Input
                      placeholder="Nature Of Business"
                      label="Nature Of Business"
                      name="nature_of_business"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nature_of_business ?? ''}
                    />
                    <ErrorMessage name="nature_of_business" />
                  </div>
                  <div>
                    <Input
                      placeholder="Years of employment"
                      label="Years of employment"
                      name="years_of_employment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      min="0"
                      onKeyPress={handleKeyPress}
                      value={values.years_of_employment ?? ''}
                    />
                    <ErrorMessage name="years_of_employment" />
                  </div>
                  <div>
                    <Input
                      placeholder="Years of current employment"
                      label="Years of current employment"
                      name="years_of_current_employment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      min="0"
                      onKeyPress={handleKeyPress}
                      value={values.years_of_current_employment ?? ''}
                    />
                    <ErrorMessage name="years_of_current_employment" />
                  </div>
                  <div>
                    <Input
                      placeholder="Years of previous employment"
                      label="Years of previous employment"
                      name="years_of_previous_employment"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      min="0"
                      onKeyPress={handleKeyPress}
                      value={values.years_of_previous_employment ?? ''}
                    />
                    <ErrorMessage name="years_of_previous_employment" />
                  </div>
                  <div>
                    <Input
                      placeholder="Professional license number"
                      label="Professional license number"
                      name="professional_license_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.professional_license_number ?? ''}
                    />
                    <ErrorMessage name="professional_license_number" />
                  </div>
                </Grid>

                {/* joint account occupation */}
                <Separator gap={26} />
                <SectionTitle title="Applicant 2 Occupation"></SectionTitle>
                <Grid lg={3}>
                  <div>
                    <Field name="employment_status_jt">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Employment Status (JT)" {...field}>
                          <option value="">Select Employment Status</option>
                          {employementStatus.map((emp) => (
                            <option
                              key={emp}
                              value={emp}
                              selected={
                                emp === values?.employment_status_jt ? true : false
                              }>
                              {emp}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="employment_status_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Eg. Banker"
                      label="Occupation (JT)"
                      name="occupation_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.occupation_jt ?? ''}
                    />
                    <ErrorMessage name="occupation_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Profession"
                      label="Profession (JT)"
                      name="profession_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.profession_jt ?? ''}
                    />
                    <ErrorMessage name="profession_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer"
                      label="Employer/Business/School (JT)"
                      name="employer_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer_jt ?? ''}
                    />
                    <ErrorMessage name="employer_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer Address"
                      label="Employer Address (JT)"
                      name="employer_address_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer_address_jt ?? ''}
                    />
                    <ErrorMessage name="employer_address_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer City/Town"
                      label="Employer City (JT)"
                      name="employer_city_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer_city_jt ?? ''}
                    />
                    <ErrorMessage name="employer_city_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer Email"
                      label="Employer Email (JT)"
                      name="employer_email_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer_email_jt ?? ''}
                    />
                    <ErrorMessage name="employer_email_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Employer Contact"
                      label="Employer Contact (JT)"
                      name="employer_contact_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.employer_contact_jt ?? ''}
                    />
                    <ErrorMessage name="employer_contact_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Nature Of Business"
                      label="Nature Of Business (JT)"
                      name="nature_of_business_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.nature_of_business_jt ?? ''}
                    />
                    <ErrorMessage name="nature_of_business_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Years of employment"
                      label="Years of employment (JT)"
                      name="years_of_employment_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      min="0"
                      onKeyPress={handleKeyPress}
                      value={values.years_of_employment_jt ?? ''}
                    />
                    <ErrorMessage name="years_of_employment_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Years of current employment"
                      label="Years of current employment (JT)"
                      name="years_of_current_employment_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      min="0"
                      onKeyPress={handleKeyPress}
                      value={values.years_of_current_employment_jt ?? ''}
                    />
                    <ErrorMessage name="years_of_current_employment_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Years of previous employment"
                      label="Years of previous employment (JT)"
                      name="years_of_previous_employment_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      type="number"
                      min="0"
                      onKeyPress={handleKeyPress}
                      value={values.years_of_previous_employment_jt ?? ''}
                    />
                    <ErrorMessage name="years_of_previous_employment_jt" />
                  </div>
                  <div>
                    <Input
                      placeholder="Professional license number"
                      label="Professional license number (JT)"
                      name="professional_license_number_jt"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.professional_license_number_jt ?? ''}
                    />
                    <ErrorMessage name="professional_license_number_jt" />
                  </div>
                </Grid>
              </Accordion>

              {/* source of funds */}
              <Accordion id="sourceoffunds" title="Source of funds">
                <Grid lg={3}>
                  <div>
                    {getComboOptions(MEMBER_INCOME_SOURCES)?.length && getComboDefaultSelected(MEMBER_INCOME_SOURCES) && (
                      <Select
                        placeholder="source of income"
                        label="Source of income"
                        name="member_income_sources"
                        getOptionValue={(option) => option!.income_source!.id}
                        getOptionLabel={(option) => option!.income_source!.name}
                        options={getComboOptions(MEMBER_INCOME_SOURCES)}
                        isMulti
                        defaultValue={
                          getComboDefaultSelected(MEMBER_INCOME_SOURCES)
                        }
                        onChange={handleComboChange}
                      />
                    )}
                    <ErrorMessage name="member_income_sources" />
                  </div>
                  <div>
                    {getComboOptions(MEMBER_CONTRIBUTION_SOURCES)?.length && getComboDefaultSelected(MEMBER_CONTRIBUTION_SOURCES) && (
                      <Select
                        placeholder="Source of contribution"
                        label="Source of contribution"
                        name="member_contribution_sources"
                        getOptionValue={(option) =>
                          option!.contribution_source!.id
                        }
                        getOptionLabel={(option) =>
                          option!.contribution_source!.name
                        }
                        options={getComboOptions(MEMBER_CONTRIBUTION_SOURCES)}
                        isMulti
                        defaultValue={
                          getComboDefaultSelected(MEMBER_CONTRIBUTION_SOURCES)
                        }
                        onChange={handleComboChange}
                      />
                    )}
                    <ErrorMessage name="member_contribution_sources" />
                  </div>
                </Grid>
              </Accordion>

              {/* expected account activity */}
              <Accordion
                id="expectedaccountactivity"
                title="Expected Account Activity">
                <Grid lg={3}>
                  <div>
                    <Input
                      placeholder="Initial Investment Amount"
                      label="Initial Investment Amount"
                      name="initial_investment_amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.initial_investment_amount ?? ''}
                      type="number"
                    />
                    <ErrorMessage name="initial_investment_amount" />
                  </div>
                  <div>
                    <Input
                      placeholder="Regular Top-up Amount"
                      label="Regular Top-up Amount(Expected)"
                      name="regular_top_up_amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.regular_top_up_amount ?? ''}
                      type="number"
                    />
                    <ErrorMessage name="regular_top_up_amount" />
                  </div>
                  <div>
                    <Input
                      placeholder="Regular Withdrawal Amount"
                      label="Regular Withdrawal Amount(Expected)"
                      name="regular_withdrawal_amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.regular_withdrawal_amount ?? ''}
                      type="number"
                    />
                    <ErrorMessage name="regular_withdrawal_amount" />
                  </div>
                  <div>
                    <Field name="anticipated_investment_activity_top_ups">
                      {({ field }: FieldProps) => (
                        <NativeSelect
                          label="Anticipated Investment Top Ups"
                          {...field}>
                          {accountActivity?.map((interval) => (
                            <option
                              placeholder='Select Anticipated Investment Top Ups'
                              key={interval}
                              value={interval}
                              selected={
                                interval ===
                                  values?.anticipated_investment_activity_top_ups || (!accountActivity.includes(values?.anticipated_investment_activity_top_ups ?? ""))
                                  ? true
                                  : false
                              }>
                              {interval}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="anticipated_investment_activity_top_ups" />

                    <div hidden={values?.anticipated_investment_activity_top_ups === "Other" ? false : true}>
                      <Input
                        placeholder="Anticipated Investment Top Ups Other"
                        label=""
                        name="anticipated_investment_activity_top_ups_other"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.anticipated_investment_activity_top_ups_other ?? ''}
                        type="text"
                      />
                      <ErrorMessage name="anticipated_investment_activity_top_ups_other" />
                    </div>
                  </div>

                  <div>
                    <Field name="anticipated_investment_activity_withdrawals">
                      {({ field }: FieldProps) => (
                        <NativeSelect
                          label="Anticipated Investment Withdrawals"
                          {...field}>
                          {accountActivity?.map((interval) => (
                            <option
                              placeholder='Select Anticipated Investment Withdrawals'
                              key={interval}
                              value={interval}
                              selected={
                                interval ===
                                  values?.anticipated_investment_activity_withdrawals
                                  ? true
                                  : false
                              }>
                              {interval}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="anticipated_investment_activity_withdrawals" />
                    <div hidden={values?.anticipated_investment_activity_withdrawals === "Other" ? false : true}>
                      <Input
                        placeholder="Anticipated Investment Withdrawals"
                        label=""
                        name="anticipated_investment_activity_withdrawals_other"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values?.anticipated_investment_activity_withdrawals_other ?? ''}
                        type="text"
                      />
                      <ErrorMessage name="anticipated_investment_activity_withdrawals_other" />
                    </div>
                  </div>
                </Grid>
              </Accordion>

              {/* investment profile */}
              <Accordion id="investmentprofile" title="Investment profile">
                <Grid lg={3}>
                  <div>
                    <Field name="client_investment_profile.investment_objective">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Investment objective" {...field}>
                          <option>Select investment objective</option>
                          {investmentObjectives?.map((interval) => (
                            <option
                              key={interval}
                              value={interval}
                              selected={
                                interval ===
                                  values?.client_investment_profile
                                    ?.investment_objective
                                  ? true
                                  : false
                              }>
                              {interval}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="client_investment_profile.investment_objective" />
                  </div>

                  <div>
                    <Field name="client_investment_profile.investment_knowledge">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Investment Knowledge" {...field}>
                          <option>Select investment knowledge</option>
                          {investmentKnowledge?.map((interval) => (
                            <option
                              key={interval}
                              value={interval}
                              selected={
                                interval ===
                                  values?.client_investment_profile
                                    ?.investment_knowledge
                                  ? true
                                  : false
                              }>
                              {interval}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="client_investment_profile.investment_knowledge" />
                  </div>

                  <div>
                    <Field name="client_investment_profile.risk_tolerance">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Risk tolerance" {...field}>
                          <option>Select risk tolerance</option>
                          {riskTolerance?.map((interval) => (
                            <option
                              key={interval}
                              value={interval}
                              selected={
                                interval ===
                                  values?.client_investment_profile
                                    ?.risk_tolerance
                                  ? true
                                  : false
                              }>
                              {interval}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="client_investment_profile.risk_tolerance" />
                  </div>

                  <div>
                    <Field name="client_investment_profile.investment_horizon">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Investment horizon" {...field}>
                          <option>Select investment horizon</option>
                          {investmentHorizons?.map((interval) => (
                            <option
                              key={interval}
                              value={interval}
                              selected={
                                interval ===
                                  values?.client_investment_profile
                                    ?.investment_horizon
                                  ? true
                                  : false
                              }>
                              {interval}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="client_investment_profile.investment_horizon" />
                  </div>

                  <div>
                    <Field name="client_investment_profile.approximate_annual_income">
                      {({ field }: FieldProps) => (
                        <NativeSelect
                          label="Approximate annual income"
                          {...field}>
                          <option>Select approximate annual income</option>
                          {approximateAnnualIncome?.map((interval) => (
                            <option
                              key={interval}
                              value={interval}
                              selected={
                                interval ===
                                  values?.client_investment_profile
                                    ?.approximate_annual_income
                                  ? true
                                  : false
                              }>
                              {interval}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="client_investment_profile.approximate_annual_income" />
                  </div>
                </Grid>
              </Accordion>

              {/* account mandate */}
              <Accordion id="accountmandate" title="Account mandate">
                <Grid lg={3}>
                  <div>
                    <Field name="account_mandate">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Account Mandate" {...field}>
                          <option>Select Account Mandate</option>
                          {accountMandates.map((mode) => (
                            <option
                              key={mode}
                              value={mode}
                              selected={
                                mode === values?.account_mandate ? true : false
                              }>
                              {mode}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="account_mandate" />
                  </div>
                </Grid>
              </Accordion>

              {/* statement service */}
              <Accordion id="statementservice" title="Statement service">
                <Grid lg={3}>
                  <div>
                    <Field name="mode_of_statement_delivery">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Mode of delivery" {...field}>
                          <option>Select Mode of delivery</option>
                          {modeOfDeliveries.map((mode) => (
                            <option
                              key={mode}
                              value={mode}
                              selected={
                                mode === values?.mode_of_statement_delivery
                                  ? true
                                  : false
                              }>
                              {mode}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="mode_of_statement_delivery" />
                  </div>

                  <div>
                    <Field name="statement_frequency">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Statement frequency" {...field}>
                          <option>Select Mode of statement frequency</option>
                          {frequencies.map((freq) => (
                            <option
                              key={freq}
                              value={freq}
                              selected={
                                freq === values?.statement_frequency
                                  ? true
                                  : false
                              }>
                              {freq}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="statement_frequency" />
                  </div>
                </Grid>
              </Accordion>

              <Accordion id="nextofkin" title="Next of kin">
                <Grid lg={3}>
                  <div>
                    <Field name="next_of_kin.title">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Title" {...field}>
                          <option>Select Title</option>
                          {titles?.title?.map((title) => (
                            <option key={title.name} value={title.name}>
                              {title.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="next_of_kin.title" />
                  </div>
                  <div>
                    <Input
                      placeholder="First name"
                      label="First name"
                      name="next_of_kin.first_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.next_of_kin?.first_name ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.first_name" />
                  </div>
                  <div>
                    <Input
                      placeholder="Last name"
                      label="Last name"
                      name="next_of_kin.last_name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.next_of_kin?.last_name ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.last_name" />
                  </div>
                  <div>
                    <Input
                      placeholder="Other names"
                      label="Other names"
                      name="next_of_kin.other_names"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.next_of_kin?.other_names ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.other_names" />
                  </div>
                  <div>
                    <Field name="next_of_kin.gender_id">
                      {({ field }: FieldProps) => (
                        <NativeSelect
                          label="Gender"
                          {...field}
                          name="next_of_kin.gender_id"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.next_of_kin?.gender_id ?? ''}>
                          <option>Select Gender</option>
                          {genders?.gender?.filter(gen => gen.id !== 4)?.map((gender) => (
                            <option key={gender.id} value={gender.id}>
                              {gender.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="next_of_kin.gender_id" />
                  </div>
                  <div>
                    <DateInput
                      label="Date of birth"
                      name="next_of_kin.date_of_birth"
                      defaultDate={
                        values.next_of_kin?.date_of_birth &&
                        new Date(values.next_of_kin?.date_of_birth)
                      }
                      onChange={handleDateChange}
                    />
                    <ErrorMessage name="next_of_kin.date_of_birth" />
                  </div>
                  <div>
                    <Input
                      placeholder="Place of birth"
                      label="Place of birth"
                      name="next_of_kin.place_of_birth"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.next_of_kin?.place_of_birth ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.place_of_birth" />
                  </div>
                  <div>
                    <Field name="next_of_kin.marital_status">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Marital Status" {...field}>
                          <option>Select martial status</option>
                          {maritalStatuses?.marital_status?.map((ms) => (
                            <option
                              key={ms.name}
                              value={ms.name}
                              selected={
                                ms.name === values?.next_of_kin?.marital_status
                                  ? true
                                  : false
                              }>
                              {ms.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="next_of_kin.marital_status" />
                  </div>
                  <div>
                    <Input
                      placeholder="Email address"
                      label="Email address"
                      name="next_of_kin.email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.next_of_kin?.email ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.email" />
                  </div>
                  <div>
                    <Input
                      placeholder="Phone"
                      label="Phone"
                      name="next_of_kin.mobile"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.next_of_kin?.mobile ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.mobile" />
                  </div>
                  <div>
                    <Input
                      placeholder="Phone 2"
                      label="Phone 2"
                      name="next_of_kin.mobile2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.next_of_kin?.mobile2 ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.mobile2" />
                  </div>
                  <div>
                    <NativeSelect
                      label="Relationship"
                      name="next_of_kin.relationship_id"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.next_of_kin?.relationship_id ?? ''}>
                      <option value="">Select relationship</option>
                      {relationships?.relationship?.filter(src => src.id !== 11).map((relationship) => (
                        <option key={relationship.id} value={relationship.id}>
                          {relationship.name}
                        </option>
                      ))}
                    </NativeSelect>
                    <ErrorMessage name="next_of_kin.relationship_id" />
                  </div>
                  <div>
                    <Input
                      placeholder="Residential Address"
                      label="Residential Address"
                      name="next_of_kin.home_address"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        values.next_of_kin?.home_address ??
                        ''
                      }
                    />
                    <ErrorMessage
                      name="next_of_kin.home_address"
                    />
                  </div>
                  <div>
                    <Input
                      placeholder="Banker"
                      label="Occupation"
                      name="next_of_kin.occupation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.next_of_kin?.occupation ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.occupation" />
                  </div>
                  <div>
                    <Input
                      placeholder="Profession"
                      label="Profession"
                      name="next_of_kin.profession"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.next_of_kin?.profession ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.profession" />
                  </div>
                  <div>
                    <Input
                      placeholder="Professional License Number"
                      label="Profession License Number"
                      name="next_of_kin.professional_license"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.next_of_kin?.professional_license ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.professional_license" />
                  </div>
                  <div>
                    <Input
                      placeholder="TIN"
                      label="TIN"
                      name="next_of_kin.tin"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.next_of_kin?.tin ?? ''}
                    />
                    <ErrorMessage name="next_of_kin.tin" />
                  </div>
                  <div>
                    <Field name="next_of_kin.residential_status">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Residential Status" {...field}>
                          <option>Select Residential Status</option>
                          {[
                            'Resident_Ghanaian',
                            'Resident_Foreigner',
                            'Non_Resident_Ghanaian',
                            'Non_Resident_Foreigner',
                          ].map((stat) => (
                            <option
                              key={stat}
                              value={stat}
                              selected={
                                stat === values?.next_of_kin?.residential_status
                                  ? true
                                  : false
                              }>
                              {stat}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="next_of_kin.residential_status" />
                  </div>
                  <div>
                    <Field name="next_of_kin.country_of_origin_id">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Country Of Origin" {...field}>
                          <option value="">Select Country Of Origin</option>
                          {countries?.country.map((country) => (
                            <option
                              key={country.id}
                              value={country.id}
                              selected={
                                country ===
                                  values?.next_of_kin?.country_of_origin_id
                                  ? true
                                  : false
                              }>
                              {country.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="next_of_kin.country_of_origin_id" />
                  </div>
                  <div>
                    <Field name="next_of_kin.region_id">
                      {({ field }: FieldProps) => (
                        <NativeSelect label="Region" {...field}>
                          <option value="">Select Region</option>
                          {regions?.region?.map((region) => (
                            <option
                              key={region.id}
                              value={region.id}
                              selected={
                                values?.next_of_kin?.region_id === region.id ? true : false
                              }
                            >
                              {region.name}
                            </option>
                          ))}
                        </NativeSelect>
                      )}
                    </Field>
                    <ErrorMessage name="next_of_kin.region_id" />
                  </div>
                  <div>
                    <Input
                      placeholder="Residential Permit Number"
                      label="Residential Permit Number"
                      name="next_of_kin.residential_permit_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        values?.next_of_kin?.residential_permit_number ?? ''
                      }
                    />
                    <ErrorMessage name="next_of_kin.residential_permit_number" />
                  </div>
                  <div>
                    <DateInput
                      label="Permit Issue Date"
                      name="next_of_kin.permit_issue_date"
                      defaultDate={
                        values?.next_of_kin?.permit_issue_date &&
                        new Date(values?.next_of_kin?.permit_issue_date)
                      }
                      onChange={handleDateChange}
                      maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                    />
                    <ErrorMessage name={`next_of_kin.permit_issue_date`} />
                  </div>
                  <div>
                    <DateInput
                      label="Permit Expiry Date"
                      name="next_of_kin.permit_expiry_date"
                      defaultDate={
                        values?.next_of_kin?.permit_expiry_date &&
                        new Date(values?.next_of_kin?.permit_expiry_date)
                      }
                      onChange={handleDateChange}
                    />
                    <ErrorMessage name={`next_of_kin.permit_expiry_date`} />
                  </div>
                  <div>
                    <Input
                      placeholder="Place of Issue"
                      label="Place of Issue"
                      name="next_of_kin.residential_place_of_issue"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={
                        values?.next_of_kin?.residential_place_of_issue ?? ''
                      }
                    />
                    <ErrorMessage name="next_of_kin.residential_place_of_issue" />
                  </div>
                  <div>
                    <Input
                      placeholder="GPS Address"
                      label="GPS Address"
                      name={`next_of_kin.gps_address`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.next_of_kin?.gps_address ?? ''}
                    />
                    <ErrorMessage name={`next_of_kin.gps_address`} />
                  </div>
                  <div>
                    <NativeSelect
                      label="ID Type"
                      name="next_of_kin.id_type"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.next_of_kin?.id_type ?? ''}>
                      <option value="">Select ID type</option>
                      {identificationTypes?.identification_type.map((type) => (
                        <option
                          key={type.name}
                          value={type.name}
                          selected={
                            type.name === values?.next_of_kin?.id_type
                              ? true
                              : false
                          }>
                          {type.name}
                        </option>
                      ))}
                    </NativeSelect>
                    <ErrorMessage name={`next_of_kin.id_type`} />
                  </div>
                  <div>
                    <Input
                      placeholder="ID number"
                      label="ID number"
                      name="next_of_kin.id_number"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values?.next_of_kin?.id_number ?? ''}
                    />
                    <ErrorMessage name={`next_of_kin.id_number`} />
                  </div>
                  <div>
                    <DateInput
                      label="Issue date"
                      name="next_of_kin.id_issue_date"
                      defaultDate={
                        values.next_of_kin?.id_issue_date &&
                        new Date(values?.next_of_kin?.id_issue_date)
                      }
                      onChange={handleDateChange}
                      maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                    />
                    <ErrorMessage name={`next_of_kin.id_issue_date`} />
                  </div>
                  <div>
                    <DateInput
                      label="Expiry date"
                      name="next_of_kin.id_expiry_date"
                      defaultDate={
                        values.next_of_kin?.id_expiry_date &&
                        new Date(values?.next_of_kin?.id_expiry_date)
                      }
                      onChange={handleDateChange}
                    />
                    <ErrorMessage name={`next_of_kin.id_expiry_date`} />
                  </div>
                </Grid>
              </Accordion>

              <Accordion id="beneficiaries" title="Beneficiaries">
                <FieldArray
                  name="beneficiaries"
                  render={(arrayHelpers: ArrayHelpers) => (
                    <Stack>
                      {values.beneficiaries?.map((beneficiaries, index) => (
                        <div key={index}>
                          <Grid lg={3}>
                            <div>
                              <Input
                                placeholder="First name"
                                label="First name"
                                name={`beneficiaries[${index}].first_name`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]?.first_name ??
                                  ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].first_name`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Last name"
                                label="Last name"
                                name={`beneficiaries[${index}].last_name`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]?.last_name ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].last_name`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Other names"
                                label="Other names"
                                name={`beneficiaries[${index}].other_names`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]?.other_names ??
                                  ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].other_names`}
                              />
                            </div>
                            <div>
                              <Field name={`beneficiaries[${index}].gender_id`}>
                                {({ field }: FieldProps) => (
                                  <NativeSelect
                                    label="Gender"
                                    {...field}>
                                    <option>Select Gender</option>
                                    {genders?.gender?.filter(gen => gen.id !== 4)?.map((gender) => (
                                      <option key={gender.id} value={gender.id}>
                                        {gender.name}
                                      </option>
                                    ))}
                                  </NativeSelect>
                                )}
                              </Field>
                              <ErrorMessage name={`beneficiaries[${index}].gender_id`} />
                            </div>
                            <div>
                              <DateInput
                                label="Date of birth"
                                name={`beneficiaries[${index}].date_of_birth`}
                                defaultDate={
                                  values.beneficiaries?.[index]
                                    ?.date_of_birth &&
                                  new Date(
                                    values.beneficiaries?.[index]?.date_of_birth
                                  )
                                }
                                onChange={handleDateChange}
                                maxDate={getDate18YearsAgo()}
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].date_of_birth`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Place of birth"
                                label="Place of birth"
                                name={`beneficiaries[${index}].place_of_birth`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values?.beneficiaries?.[index]
                                    ?.place_of_birth ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].place_of_birth`}
                              />
                            </div>
                            <div>
                              <Field
                                name={`beneficiaries[${index}].marital_status`}>
                                {({ field }: FieldProps) => (
                                  <NativeSelect
                                    label="Marital Status"
                                    {...field}>
                                    <option>Select martial status</option>
                                    {maritalStatuses?.marital_status?.map(
                                      (ms) => (
                                        <option key={ms.name} value={ms.name}>
                                          {ms.name}
                                        </option>
                                      )
                                    )}
                                  </NativeSelect>
                                )}
                              </Field>
                              <ErrorMessage
                                name={`beneficiaries[${index}].marital_status`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Email address"
                                label="Email address"
                                name={`beneficiaries[${index}].email`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]?.email ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].email`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Phone"
                                label="Phone"
                                name={`beneficiaries[${index}].mobile`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]?.mobile ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].mobile`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Phone 2"
                                label="Phone"
                                name={`beneficiaries[${index}].mobile2`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]?.mobile2 ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].mobile2`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Residential Address"
                                label="Residential Address"
                                name={`beneficiaries[${index}].home_address`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]?.home_address ??
                                  ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].home_address`}
                              />
                            </div>
                            <div>
                              <Field
                                name={`beneficiaries[${index}].relationship_id`}>
                                {({ field }: FieldProps) => (
                                  <NativeSelect label="Relationship" {...field}>
                                    <option value="">select</option>
                                    {relationships?.relationship?.filter(src => src.id !== 11).map(
                                      (relationship) => (
                                        <option
                                          key={relationship.id}
                                          value={relationship.id}>
                                          {relationship.name}
                                        </option>
                                      )
                                    )}
                                  </NativeSelect>
                                )}
                              </Field>
                              <ErrorMessage
                                name={`beneficiaries[${index}].relationship_id`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Allocation"
                                label="Allocation"
                                name={`beneficiaries[${index}].allocation`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]?.allocation ??
                                  ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].allocation`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Banker"
                                label="Occupation"
                                name={`beneficiaries[${index}].occupation`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values?.beneficiaries?.[index]?.occupation ??
                                  ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].occupation`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Profession"
                                label="Profession"
                                name={`beneficiaries[${index}].profession`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values?.beneficiaries?.[index]?.profession ??
                                  ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].profession`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Professional License Number"
                                label="Profession License Number"
                                name={`beneficiaries[${index}].professional_license`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values?.beneficiaries?.[index]
                                    ?.professional_license ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].professional_license`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="TIN"
                                label="TIN"
                                name={`beneficiaries[${index}].tin`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values?.beneficiaries?.[index]?.tin ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].tin`}
                              />
                            </div>
                            <div>
                              <Field
                                name={`beneficiaries[${index}].residential_status`}>
                                {({ field }: FieldProps) => (
                                  <NativeSelect
                                    label="Residential Status"
                                    {...field}>
                                    <option>Select Residential Status</option>
                                    {[
                                      'Resident_Ghanaian',
                                      'Resident_Foreigner',
                                      'Non_Resident_Ghanaian',
                                      'Non_Resident_Foreigner',
                                    ].map((stat) => (
                                      <option
                                        key={stat}
                                        value={stat}
                                        selected={
                                          stat ===
                                            values?.beneficiaries?.[index]
                                              ?.residential_status
                                            ? true
                                            : false
                                        }>
                                        {stat}
                                      </option>
                                    ))}
                                  </NativeSelect>
                                )}
                              </Field>
                              <ErrorMessage
                                name={`beneficiaries[${index}].residential_status`}
                              />
                            </div>
                            <div>
                              <Field
                                name={`beneficiaries[${index}].country_of_origin_id`}>
                                {({ field }: FieldProps) => (
                                  <NativeSelect
                                    label="Country Of Origin"
                                    {...field}>
                                    <option value="">
                                      Select Country Of Origin
                                    </option>
                                    {countries?.country.map((country) => (
                                      <option
                                        key={country.id}
                                        value={country.id}
                                        selected={
                                          country ===
                                            values?.beneficiaries?.[index]
                                              ?.country_of_origin_id
                                            ? true
                                            : false
                                        }>
                                        {country.name}
                                      </option>
                                    ))}
                                  </NativeSelect>
                                )}
                              </Field>
                              <ErrorMessage
                                name={`beneficiaries[${index}].country_of_origin_id`}
                              />
                            </div>
                            <div>
                              <Field name={`beneficiaries[${index}].region_id`}>
                                {({ field }: FieldProps) => (
                                  <NativeSelect label="Region" {...field}>
                                    <option value="">Select Region</option>
                                    {regions?.region?.map((region) => (
                                      <option
                                        key={region.id}
                                        value={region.id}
                                        selected={
                                          values?.beneficiaries?.[index]?.region_id === region.id ? true : false
                                        }
                                      >
                                        {region.name}
                                      </option>
                                    ))}
                                  </NativeSelect>
                                )}
                              </Field>
                              <ErrorMessage name={`beneficiaries[${index}].region_id`} />
                            </div>
                            <div>
                              <Input
                                placeholder="Residential Permit Number"
                                label="Residential Permit Number"
                                name={`beneficiaries[${index}].residential_permit_number`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]
                                    ?.residential_permit_number ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].residential_permit_number`}
                              />
                            </div>
                            <div>
                              <DateInput
                                label="Permit Issue Date"
                                name={`beneficiaries[${index}].permit_issue_date`}
                                defaultDate={
                                  values?.beneficiaries?.[index]
                                    ?.permit_issue_date &&
                                  new Date(
                                    values.beneficiaries?.[
                                      index
                                    ]?.permit_issue_date
                                  )
                                }
                                onChange={handleDateChange}
                                maxDate={moment(
                                  new Date(),
                                  'YYYY-MM-DD'
                                ).toDate()}
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].permit_issue_date`}
                              />
                            </div>
                            <div>
                              <DateInput
                                label="Permit Expiry Date"
                                name={`beneficiaries[${index}].permit_expiry_date`}
                                defaultDate={
                                  values?.beneficiaries?.[index]
                                    ?.permit_expiry_date &&
                                  new Date(
                                    values.beneficiaries?.[
                                      index
                                    ]?.permit_expiry_date
                                  )
                                }
                                onChange={handleDateChange}
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].permit_expiry_date`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Place of Issue"
                                label="Place of Issue"
                                name={`beneficiaries[${index}].residential_place_of_issue`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]
                                    ?.residential_place_of_issue ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].residential_place_of_issue`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="GPS Address"
                                label="GPS Address"
                                name={`beneficiaries[${index}].gps_address`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.beneficiaries?.[index]?.gps_address ??
                                  ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].gps_address`}
                              />
                            </div>
                            <div>
                              <NativeSelect
                                label="ID Type"
                                name={`beneficiaries[${index}].id_type`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values?.beneficiaries?.[index]?.id_type ?? ''
                                }>
                                <option value="">Select ID type</option>
                                {identificationTypes?.identification_type.map(
                                  (type) => (
                                    <option
                                      key={type.name}
                                      value={type.name}
                                      selected={
                                        type.name ===
                                          values?.beneficiaries?.[index]?.id_type
                                          ? true
                                          : false
                                      }>
                                      {type.name}
                                    </option>
                                  )
                                )}
                              </NativeSelect>
                              <ErrorMessage
                                name={`beneficiaries[${index}].id_type`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="ID number"
                                label="ID number"
                                name={`beneficiaries[${index}].id_number`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values?.beneficiaries?.[index]?.id_number ??
                                  ''
                                }
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].id_number`}
                              />
                            </div>
                            <div>
                              <DateInput
                                label="Issue date"
                                name={`beneficiaries[${index}].id_issue_date`}
                                defaultDate={
                                  values.beneficiaries?.[index]
                                    ?.id_issue_date &&
                                  new Date(
                                    values?.beneficiaries?.[
                                      index
                                    ]?.id_issue_date
                                  )
                                }
                                onChange={handleDateChange}
                                maxDate={moment(
                                  new Date(),
                                  'YYYY-MM-DD'
                                ).toDate()}
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].id_issue_date`}
                              />
                            </div>

                            <div>
                              <DateInput
                                label="Expiry date"
                                name={`beneficiaries[${index}].id_expiry_date`}
                                defaultDate={
                                  values.beneficiaries?.[index]
                                    ?.id_expiry_date &&
                                  new Date(
                                    values?.beneficiaries?.[
                                      index
                                    ]?.id_expiry_date
                                  )
                                }
                                onChange={handleDateChange}
                              />
                              <ErrorMessage
                                name={`beneficiaries[${index}].id_expiry_date`}
                              />
                            </div>
                            {!!index && (
                              <Flex
                                onClick={() => arrayHelpers.remove(index)}
                                jc="flex-end"
                                styles={{
                                  marginTop: '40px',
                                }}>
                                <Avatar
                                  size="xs"
                                  background={theme.colors.red[100]}>
                                  <Icon
                                    size="sm"
                                    color={theme.colors.red[500]}
                                    icon={['fas', 'minus']}></Icon>
                                </Avatar>
                              </Flex>
                            )}
                          </Grid>
                          <Separator />
                        </div>
                      ))}
                      <Flex>
                        <Button
                          type="button"
                          action={() => arrayHelpers.push(NewBeneficiary)}>
                          Add  {values.beneficiaries?.length ? 'another' : ''}{' '}
                          beneficiary
                        </Button>
                      </Flex>
                    </Stack>
                  )}
                />
              </Accordion>

              <Accordion id="dependents" title="Dependents">
                <FieldArray
                  name="member_dependents"
                  render={(arrayHelpers: ArrayHelpers) => (
                    <Stack>
                      {values.member_dependents?.map((dependent, index) => (
                        <div key={index}>
                          <Grid lg={3}>
                            <div>
                              <Input
                                placeholder="Name"
                                label="Name"
                                name={`member_dependents[${index}].name`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.member_dependents?.[index]?.name ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`member_dependents[${index}].name`}
                              />
                            </div>
                            <div>
                              <Input
                                placeholder="Age"
                                label="Age"
                                name={`member_dependents[${index}].age`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={
                                  values.member_dependents?.[index]?.age ?? ''
                                }
                              />
                              <ErrorMessage
                                name={`member_dependents[${index}].age`}
                              />
                            </div>
                            {!!index && (
                              <Flex
                                onClick={() => arrayHelpers.remove(index)}
                                jc="flex-end"
                                styles={{
                                  marginTop: '40px',
                                }}>
                                <Avatar
                                  size="xs"
                                  background={theme.colors.red[100]}>
                                  <Icon
                                    size="sm"
                                    color={theme.colors.red[500]}
                                    icon={['fas', 'minus']}></Icon>
                                </Avatar>
                              </Flex>
                            )}
                          </Grid>
                          <Separator />
                        </div>
                      ))}
                      <Flex>
                        <Button
                          type="button"
                          action={() => arrayHelpers.push(NewDependent)}>
                          Add  {values.member_dependents?.length ? 'another' : ''}{' '}
                          dependent
                        </Button>
                      </Flex>
                    </Stack>
                  )}
                />
              </Accordion>

              <Accordion id="paymentchannels" title="Payment Channels">
                <FieldArray
                  name="member_payment_instructions"
                  render={(arrayHelpers: ArrayHelpers) => (
                    <Stack>
                      {values.member_payment_instructions?.filter(paymentInfo => paymentInfo.payment_provider?.id !== 30).map(
                        (instruction, index) => (
                          <div key={index}>
                            <Grid lg={3}>
                              <div>
                                <Field
                                  name={`member_payment_instructions[${index}].payment_provider.payment_channel_id`}>
                                  {({ field }: FieldProps) => (
                                    <NativeSelect label="Type" {...field}>
                                      <option value="">Select type</option>
                                      {paymentChannels?.payment_channel?.map(
                                        (channel) => (
                                          <option
                                            key={channel.id}
                                            value={channel.id}>
                                            {channel.name}
                                          </option>
                                        )
                                      )}
                                    </NativeSelect>
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={`member_payment_instructions[${index}].payment_provider.payment_channel_id`}
                                />
                              </div>
                              <div>
                                <Field
                                  name={`member_payment_instructions[${index}].payment_provider.id`}>
                                  {({ field }: FieldProps) => (
                                    <NativeSelect label="Provider" {...field}>
                                      <option value="">Select provider</option>
                                      {getPaymentProviders(
                                        instruction.payment_provider
                                          .payment_channel_id
                                      )?.map((provider) => (
                                        <option
                                          key={provider.id}
                                          value={provider.id}>
                                          {provider.name}
                                        </option>
                                      ))}
                                    </NativeSelect>
                                  )}
                                </Field>
                                <ErrorMessage
                                  name={`member_payment_instructions[${index}].payment_provider.id`}
                                />
                              </div>
                              {Number(
                                instruction.payment_provider.payment_channel_id
                              ) === 1 && (
                                  <React.Fragment>
                                    <div>
                                      <Field
                                        name={`member_payment_instructions[${index}].branch_id`}>
                                        {({ field }: FieldProps) => (
                                          <NativeSelect label="Branch" {...field}>
                                            <option value="">
                                              Select branch
                                            </option>
                                            {getBranches(
                                              instruction.payment_provider.id
                                            )?.map((branch) => (
                                              <option
                                                key={branch.id}
                                                value={branch.id}>
                                                {branch.name}
                                              </option>
                                            ))}
                                          </NativeSelect>
                                        )}
                                      </Field>
                                      <ErrorMessage
                                        name={`member_payment_instructions[${index}].payment_provider.id`}
                                      />
                                    </div>
                                    <div>
                                      <Input
                                        placeholder="Account name"
                                        label="Account name"
                                        name={`member_payment_instructions[${index}].account_name`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                          values.member_payment_instructions?.[
                                            index
                                          ]?.account_name ?? ''
                                        }
                                      />
                                      <ErrorMessage
                                        name={`member_payment_instructions[${index}].account_name`}
                                      />
                                    </div>
                                    <div>
                                      <Input
                                        placeholder="Account number"
                                        label="Account number"
                                        name={`member_payment_instructions[${index}].account_number`}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                          values.member_payment_instructions?.[
                                            index
                                          ]?.account_number ?? ''
                                        }
                                      />
                                      <ErrorMessage
                                        name={`member_payment_instructions[${index}].account_number`}
                                      />
                                    </div>
                                  </React.Fragment>
                                )}
                              {Number(
                                instruction.payment_provider.payment_channel_id
                              ) === 2 && (
                                  <div>
                                    <Input
                                      placeholder="024378766"
                                      label="Mobile Money Number"
                                      name={`member_payment_instructions[${index}].mobile_money_number`}
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={
                                        values.member_payment_instructions?.[
                                          index
                                        ]?.mobile_money_number ?? ''
                                      }
                                    />
                                    <ErrorMessage
                                      name={`member_payment_instructions[${index}].mobile_money_number`}
                                    />
                                  </div>
                                )}
                              {!!index && (
                                <Flex
                                  onClick={() => arrayHelpers.remove(index)}
                                  jc="flex-end"
                                  styles={{
                                    marginTop: '40px',
                                  }}>
                                  <Avatar
                                    size="xs"
                                    background={theme.colors.red[100]}>
                                    <Icon
                                      size="sm"
                                      color={theme.colors.red[500]}
                                      icon={['fas', 'minus']}></Icon>
                                  </Avatar>
                                </Flex>
                              )}
                            </Grid>
                            <Separator />
                          </div>
                        )
                      )}
                      <Flex>
                        <Button
                          type="button"
                          action={() => arrayHelpers.push(NewChannel)}>
                          Add  {values.member_payment_instructions?.length ? 'another' : ''}{' '}
                          payment channel
                        </Button>
                      </Flex>
                    </Stack>
                  )}
                />
              </Accordion>

              <Accordion
                id="modeofinstruction"
                title="Mode of Instruction & Communication">
                <FieldArray
                  name="member_mode_of_instruction_communications"
                  render={(arrayHelpers: ArrayHelpers) => (
                    <Grid lg={3}>
                      {values?.member_mode_of_instruction_communications?.length && modeOfInstructions?.mode_of_instruction_communication?.filter(src => src.id !== 4).map(
                        (mode, index) => (
                          <BoxSelect
                            key={mode.name}
                            icon={{ name: pickModeIcon(mode.name) }}
                            isChecked={isModeChecked(mode.id)}
                            label={mode.name}
                            onChange={(e) => {
                              if (e.target.checked) {
                                arrayHelpers.push({
                                  member_id: values.id,
                                  mode_of_instruction_communication_id: mode.id,
                                });
                              } else {
                                arrayHelpers.remove(index);
                              }
                            }}
                          />
                        )
                      )}
                    </Grid>
                  )}
                />
              </Accordion>

              <Accordion id="identification" title="Identification">
                <Grid>
                  <GridItem span={6}>
                    <StatCard title="Identification">
                      <Stack css={{ padding: '24px' }}>
                        <div>
                          <NativeSelect
                            label="ID Type"
                            name="member_identifications[0].identification_type.id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.member_identifications?.[0]
                                ?.identification_type?.id ?? ''
                            }>
                            <option value="">Select ID type</option>
                            {identificationTypes?.identification_type.map(
                              (type) => (
                                <option key={type.id} value={type.id}>
                                  {type.name}
                                </option>
                              )
                            )}
                          </NativeSelect>
                          <ErrorMessage
                            name={`member_identifications[0].identification_type_id`}
                          />
                        </div>
                        <div>
                          <Input
                            placeholder="ID number"
                            label="ID number"
                            name="member_identifications[0].identification_number"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.member_identifications?.[0]
                                ?.identification_number ?? ''
                            }
                          />
                          <ErrorMessage
                            name={`member_identifications[0].identification_number`}
                          />
                        </div>
                        <div>
                          <NativeSelect
                            label="Issue Country"
                            name="member_identifications[0].country.id"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.member_identifications?.[0]?.country?.id ??
                              ''
                            }>
                            <option value="">Select issue country</option>
                            {countries?.country.map((country) => (
                              <option key={country.id} value={country.id}>
                                {country.name}
                              </option>
                            ))}
                          </NativeSelect>
                          <ErrorMessage
                            name={`member_identifications[0].country.id`}
                          />
                        </div>
                        <div>
                          <Input
                            placeholder="Place of issue"
                            label="Place of issue"
                            name="member_identifications[0].place_of_issue"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={
                              values.member_identifications?.[0]
                                ?.place_of_issue ?? ''
                            }
                          />
                          <ErrorMessage
                            name={`member_identifications[0].place_of_issue`}
                          />
                        </div>
                        <div>
                          <DateInput
                            label="Issue date"
                            name="member_identifications[0].identification_issue_date"
                            defaultDate={
                              values.member_identifications?.[0]
                                ?.identification_issue_date &&
                              new Date(
                                values.member_identifications?.[0]?.identification_issue_date
                              )
                            }
                            onChange={handleDateChange}
                            maxDate={moment(new Date(), 'YYYY-MM-DD').toDate()}
                          />
                          <ErrorMessage
                            name={`member_identifications[0].identification_issue_date`}
                          />
                        </div>

                        <div>
                          <DateInput
                            label="Expiry date"
                            name="member_identifications[0].identification_expiry_date"
                            defaultDate={
                              values.member_identifications?.[0]
                                ?.identification_expiry_date &&
                              new Date(
                                values.member_identifications?.[0]?.identification_expiry_date
                              )
                            }
                            onChange={handleDateChange}
                          />
                          <ErrorMessage
                            name={`member_identifications[0].identification_expiry_date`}
                          />
                        </div>
                        <div>
                          <FileUploader
                            id="identification_"
                            label="Upload ID"
                            onChange={handleIdImageChange}
                          />
                          <ErrorMessage name="id_file" />
                          {idImagePreviewURL && (
                            <Flex styles={{ margin: '10px' }}>
                              <img
                                src={idImagePreviewURL}
                                width="250"
                                height="250"
                                alt="idpreview"
                              />
                            </Flex>
                          )}
                        </div>
                      </Stack>
                    </StatCard>
                  </GridItem>
                  <GridItem span={6}>
                    <StatCard title="Signature">
                      <Stack css={{ padding: '24px' }}>
                        <div>
                          <FileUploader
                            id="signature"
                            onChange={handleSignatureChange}
                          />
                          <ErrorMessage name="signature" />
                          {values.signature && (
                            <Flex styles={{ margin: '10px' }}>
                              <img
                                src={values.signature}
                                width="250"
                                height="250"
                                alt="signature"
                              />
                            </Flex>
                          )}
                        </div>
                      </Stack>
                    </StatCard>
                  </GridItem>
                </Grid>
              </Accordion>
            </Stack>
          </Form>
        </GridItem>
      </Grid>
    </React.Fragment>
  );
};

export default () => {
  const { userId } = useAuthState();
  const history = useHistory();
  const [uploadOptions, setUploadOptions] = React.useState({
    status: '',
    filename: '',
  });

  //graphql error state
  const [requestError, setrequestError] = useState({ message: '' });

  const { id } = useParams();
  const { data: prospect } = useProspectDetailQuery({
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
  });
  const [
    updateProspect,
    { loading, data, error },
  ] = useUpdate_User_DetailMutation();
  const [
    generateSignedURLForUpload,
  ] = useGenerate_Signeds3_Request_For_UploadMutation();
  const prospectData = prospect?.member?.[0];

  const handleSubmit = (
    values: FormData,
    { setSubmitting }: FormikHelpers<FormData>
  ) => {
    if (!values.member_identifications?.[0]?.identification_card_image_url) {
      setUploadOptions({
        status: 'loading',
        filename: '',
      });
      uploadIdImage(values);
    } else {
      saveProspect(values);
    }
    setSubmitting(false);
  };

  const notify = (success: boolean, message: string) => {
    toast(<Toastify {...{ success, message }} />, {
      position: 'top-right',
      autoClose: 3000,
      progressStyle: {
        background: success ? theme.colors.green[200] : theme.colors.red[200],
      },
    });
  };

  const uploadIdImage = async (values: FormData) => {
    const file = values.id_file;

    if (file) {
      const filetype = file.name.split('.')[1];
      const uniqueIdentifier = values.user!.email!.split('@')[0];
      const filename = formatFilenameForS3IDUpload(filetype, uniqueIdentifier);

      const response = await generateSignedURLForUpload({
        variables: {
          filename,
          filetype,
        },
      });

      const signedRequest =
        response.data?.generateSignedS3RequestForUpload.signedRequest;
      if (signedRequest) {
        const options = {
          headers: {
            'Content-Type': filetype,
          },
        };
        axios
          .put(signedRequest, file, options)
          .then(() => {
            setUploadOptions((options) => ({
              ...options,
              status: 'success',
              filename,
            }));
            saveProspect(values, filename);
          })
          .catch(() => {
            setUploadOptions({
              status: 'failed',
              filename: '',
            });
          });
      }
    } else {
      setUploadOptions({
        status: 'failed',
        filename: '',
      });
    }
  };

  const saveProspect = (values: FormData, filename?: string) => {
    console.log(values)
    const member_changes = {
      nationality: values?.nationality,
      psl_code: values.psl_code,
      age: values.age,
      tin: values?.tin,
      tin_jt: values?.tin_jt,
      city: values?.city,
      postal_address: values?.postal_address,
      home_address: values?.home_address,
      home_address_jt: values?.home_address_jt,
      occupation: values?.occupation,
      occupation_jt: values?.occupation_jt,
      profession: values?.profession,
      profession_jt: values?.profession_jt,
      employer: values?.employer,
      employer_jt: values?.employer_jt,
      title_id: values?.title_id,
      marital_status_id: values?.marital_status_id,
      marital_status_id_jt: values?.marital_status_id_jt,
      place_of_birth: values?.place_of_birth,
      place_of_birth_jt: values?.place_of_birth_jt,
      industry_id: values?.industry_id,
      signature: values?.signature,
      residential_status: values?.residential_status,
      residential_status_jt: values?.residential_status_jt,
      country_of_origin_id: values?.country_of_origin_id,
      country_of_origin_id_jt: values?.country_of_origin_id_jt,
      country_of_residence_id: values?.country_of_residence_id,
      country_of_residence_id_jt: values?.country_of_residence_id_jt,
      region_id: values?.region_id,
      permit_expiry_date: values?.permit_expiry_date,
      permit_expiry_date_jt: values?.permit_expiry_date_jt,
      permit_issue_date: values?.permit_issue_date,
      permit_issue_date_jt: values?.permit_issue_date_jt,
      regular_top_up_amount: values?.regular_top_up_amount,
      initial_investment_amount: values?.initial_investment_amount,
      regular_withdrawal_amount: values?.regular_withdrawal_amount,
      anticipated_investment_activity_top_ups:
        values?.anticipated_investment_activity_top_ups,
      anticipated_investment_activity_top_ups_other:
        values?.anticipated_investment_activity_top_ups_other,
      anticipated_investment_activity_withdrawals:
        values?.anticipated_investment_activity_withdrawals,
      anticipated_investment_activity_withdrawals_other:
        values?.anticipated_investment_activity_withdrawals_other,
      residential_permit_number: values?.residential_permit_number,
      residential_permit_number_jt: values?.residential_permit_number_jt,
      residential_place_of_issue: values?.residential_place_of_issue,
      residential_place_of_issue_jt: values?.residential_place_of_issue_jt,
      employer_address: values?.employer_address,
      employer_address_jt: values?.employer_address_jt,
      employer_city: values?.employer_city,
      employer_city_jt: values?.employer_city_jt,
      employer_contact: values?.employer_contact,
      employer_contact_jt: values?.employer_contact_jt,
      employer_email: values?.employer_email,
      employer_email_jt: values?.employer_email_jt,
      employment_status: values?.employment_status,
      employment_status_jt: values?.employment_status_jt,
      nature_of_business: values?.nature_of_business,
      nature_of_business_jt: values?.nature_of_business_jt,
      years_of_current_employment: values?.years_of_current_employment,
      years_of_current_employment_jt: values?.years_of_current_employment_jt,
      years_of_employment: values?.years_of_employment,
      years_of_employment_jt: values?.years_of_employment_jt,
      years_of_previous_employment: values?.years_of_previous_employment,
      years_of_previous_employment_jt: values?.years_of_previous_employment_jt,
      professional_license_number: values?.professional_license_number,
      professional_license_number_jt: values?.professional_license_number_jt,
      mode_of_statement_delivery: values?.mode_of_statement_delivery,
      statement_frequency: values?.statement_frequency,
      account_mandate: values?.account_mandate,
      gps_address: values?.gps_address,
      gps_address_jt: values?.gps_address_jt,
      id_type_jt: values?.id_type_jt,
      id_number_jt: values?.id_number_jt,
      id_issue_date_jt: values?.id_issue_date_jt,
      id_expiry_date_jt: values?.id_expiry_date_jt,
      place_of_issue_jt: values?.place_of_issue_jt,
    };

    const user_changes = {
      first_name: values?.user?.first_name,
      first_name_jt: values?.user?.first_name_jt,
      other_name: values?.user?.other_name,
      other_name_jt: values?.user?.other_name_jt,
      last_name: values?.user?.last_name,
      last_name_jt: values?.user?.last_name_jt,
      email: values?.user?.email?.toLowerCase(),
      email_jt: values?.user?.email_jt?.toLowerCase(),
      date_of_birth: values?.user?.date_of_birth,
      date_of_birth_jt: values?.user?.date_of_birth_jt,
      mobile: values?.user?.mobile,
      mobile_jt: values?.user?.mobile_jt,
      gender_id: values?.user?.gender_id,
      gender_id_jt: values?.user?.gender_id_jt,
      modified_by: userId,
    };

    const member_client_investment_profile_changes = {
      investment_objective:
        values?.client_investment_profile?.investment_objective,
      investment_knowledge:
        values?.client_investment_profile?.investment_knowledge,
      risk_tolerance: values?.client_investment_profile?.risk_tolerance,
      investment_horizon: values?.client_investment_profile?.investment_horizon,
      approximate_annual_income:
        values?.client_investment_profile?.approximate_annual_income,
    };
    const member_client_investment_profile_id: BigInteger =
      values?.client_investment_profile?.id;
    const member_next_of_kin_id: BigInteger = values?.next_of_kin?.id;

    const member_next_of_kin_changes = {
      first_name: values?.next_of_kin?.first_name,
      other_names: values?.next_of_kin?.other_names,
      last_name: values?.next_of_kin?.last_name,
      mobile: values?.next_of_kin?.mobile,
      date_of_birth: values?.next_of_kin?.date_of_birth,
      relationship_id: values?.next_of_kin?.relationship_id,
      email: values?.next_of_kin?.email,
      home_address: values?.next_of_kin?.home_address,
      gender_id: values?.next_of_kin?.gender_id,
      residential_status: values?.next_of_kin?.residential_status,
      residential_place_of_issue: values?.next_of_kin?.residential_place_of_issue,
      residential_permit_number: values?.next_of_kin?.residential_permit_number,
      country_of_origin_id: values?.next_of_kin?.country_of_origin_id,
      permit_expiry_date: values?.next_of_kin?.permit_expiry_date,
      permit_issue_date: values?.next_of_kin?.permit_issue_date,
      occupation: values?.next_of_kin?.occupation,
      profession: values?.next_of_kin?.profession,
      mobile2: values?.next_of_kin?.mobile2,
      gps_address: values?.next_of_kin?.gps_address,
      title: values?.next_of_kin?.title,
      place_of_birth: values?.next_of_kin?.place_of_birth,
      marital_status: values?.next_of_kin?.marital_status,
      tin: values?.next_of_kin?.tin,
      id_type: values?.next_of_kin?.id_type,
      id_number: values?.next_of_kin?.id_number,
      id_expiry_date: values?.next_of_kin?.id_expiry_date,
      id_issue_date: values?.next_of_kin?.id_issue_date,
      professional_license: values?.next_of_kin?.professional_license,
      region_id: values?.next_of_kin?.region_id,
      modified_by: userId,
    };

    const identification = values.member_identifications?.[0];
    const member_identification_changes = {
      identification_type_id: identification?.identification_type.id,
      identification_number: identification?.identification_number,
      issue_country_id: identification?.country?.id,
      place_of_issue: identification?.place_of_issue,
      identification_issue_date: identification?.identification_issue_date,
      identification_expiry_date: identification?.identification_expiry_date,
      identification_card_image_url:
        identification?.identification_card_image_url || filename,
      member_id: values.id,
      modified_by: userId,
    };

    const member_goals_changes =
      values?.member_goals?.map((goal: Partial<Member_Goal>) => ({
        guid: goal.guid,
        years_to_fund: goal.years_to_fund,
        accepted: goal.accepted,
        duration_months: goal.duration_months,
        duration_years: goal.duration_years,
        expected_return: goal.expected_return,
        has_insurance: !!goal.has_insurance,
        goal_id: goal.goal_id,
        accepted_on: goal.accepted_on,
        ...(!!goal.has_insurance && {
          goal_insurances: {
            data:
              goal.goal_insurances?.map((goal_insurance) => ({
                ...(goal_insurance?.id && { id: goal_insurance?.id }),
                insurance_premium: goal_insurance?.insurance_premium,
                insurance_rate_per_1000:
                  goal_insurance?.insurance_rate_per_1000,
                insurance_type_id: goal_insurance?.insurance_type_id,
              })) || [],
            on_conflict: {
              constraint: Goal_Insurance_Constraint.GoalInsurancePkey,
              update_columns: [
                Goal_Insurance_Update_Column.InsurancePremium,
                Goal_Insurance_Update_Column.InsuranceRatePer_1000,
                Goal_Insurance_Update_Column.InsuranceTypeId,
              ],
            },
          },
        }),
        monthly_contribution: goal.monthly_contribution,
        future_value: goal.future_value,
        current_value: goal.current_value,
        goal_for: goal.goal_for,
        administrative_fees: goal.administrative_fees,
        member_id: values.id,
        ...(!goal.id && { created_by: userId }),
        modified_by: userId,
      })) || [];

    const member_beneficiary_changes =
      values?.beneficiaries?.map((beneficiary) => ({
        guid: beneficiary.guid,
        first_name: beneficiary.first_name,
        other_names: beneficiary.other_names,
        last_name: beneficiary.last_name,
        mobile: beneficiary.mobile,
        home_address: beneficiary.home_address,
        date_of_birth: beneficiary.date_of_birth,
        relationship_id: beneficiary.relationship_id,
        allocation: beneficiary.allocation,
        residential_status: beneficiary.residential_status,
        residential_place_of_issue: beneficiary.residential_place_of_issue,
        residential_permit_number: beneficiary.residential_permit_number,
        country_of_origin_id: beneficiary.country_of_origin_id,
        permit_expiry_date: beneficiary.permit_expiry_date,
        permit_issue_date: beneficiary.permit_issue_date,
        occupation: beneficiary.occupation,
        profession: beneficiary.profession,
        mobile2: beneficiary.mobile2,
        gps_address: beneficiary.gps_address,
        title: beneficiary.title,
        place_of_birth: beneficiary.place_of_birth,
        marital_status: beneficiary.marital_status,
        tin: beneficiary.tin,
        id_type: beneficiary.id_type,
        id_number: beneficiary.id_number,
        id_expiry_date: beneficiary.id_expiry_date,
        id_issue_date: beneficiary.id_issue_date,
        professional_license: beneficiary.professional_license,
        region_id: beneficiary?.region_id,
        gender_id: beneficiary?.gender_id,
        email: beneficiary?.email,
        member_id: values.id,
        ...(!beneficiary.id && { created_by: userId }),
        modified_by: userId,
      })) || [];

    const member_payment_instruction_changes =
      values?.member_payment_instructions?.map((payment_info) => ({
        guid: payment_info.guid,
        payment_provider_id: payment_info.payment_provider?.id,
        account_name: payment_info.account_name,
        account_number: payment_info.account_number,
        branch_id: payment_info.branch_id,
        preferred: !!payment_info.preferred,
        mobile_money_number: payment_info.mobile_money_number,
        member_id: values.id,
        ...(!payment_info.id && { created_by: userId }),
        modified_by: userId,
      })) || [];

    const member_mode_of_instruction_changes =
      values.member_mode_of_instruction_communications?.map((mode) => ({
        member_id: values.id,
        mode_of_instruction_communication_id:
          mode.mode_of_instruction_communication_id,
      })) || [];

    const member_income_source_change =
      values?.member_income_sources?.map((income_source) => ({
        income_source_id: income_source?.income_source.id,
        member_id: values.id,
      })) || [];

    const member_contribution_source_change =
      values?.member_contribution_sources?.map((contribution_source) => ({
        contribution_source_id: contribution_source?.contribution_source.id,
        member_id: values.id,
      })) || [];

    const member_dependent_changes =
      values?.member_dependents?.map((dependent) => ({
        guid: dependent.guid,
        name: dependent.name,
        age: dependent.age,
        member_id: values.id,
        ...(!dependent.id && { created_by: userId }),
        modified_by: userId,
      })) || [];

    updateProspect({
      variables: {
        id: values.id,
        member_changes,
        user_changes,
        member_next_of_kin_id,
        member_next_of_kin_changes,
        member_client_investment_profile_id,
        member_client_investment_profile_changes,
        member_identification_changes,
        member_goals_changes,
        member_beneficiary_changes,
        member_payment_instruction_changes,
        member_mode_of_instruction_changes,
        member_income_source_change,
        member_contribution_source_change,
        member_dependent_changes,
      },
      refetchQueries: ['ProspectDetail'],
      awaitRefetchQueries: true,
    })
      .then((data) => console.log(data))
      .catch((error) => setrequestError(error));

    notify(true, 'Customer updated successfully.');
    history.push(`/dashboard/customers/${values?.user?.id}`);
  };

  const validate = (values: FormData) => {
    const paymentInstructions = values.member_payment_instructions?.filter(mp => mp.payment_provider.id !== 30)
    const newValues = { ...values, member_payment_instructions: paymentInstructions }
    return EditCustomerJointTrustSchema.validate(newValues, {
      abortEarly: false,
      context: { beneficiaries: newValues.beneficiaries },
    })
      .then(() => {
        return {};
      })
      .catch((err) => {
        return yupToFormErrors(err);
      });
  };

  return (
    <Formik
      initialValues={prospectData || {}}
      validate={validate}
      onSubmit={handleSubmit}
      enableReinitialize>
      <EditJointCustomer
        id={id}
        prospect={prospectData}
        loading={loading || uploadOptions.status === 'loading'}
        data={!!data?.update_member?.returning?.[0]}
        error={!!error || uploadOptions.status === 'failed'}
        errorMessage={requestError?.message}
      />
    </Formik>
  );
};
